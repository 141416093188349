import React, { useEffect  } from 'react'

const IndexPage = () => {

  useEffect(() => {
    const [ , , subdomain] = window.location.hostname.split(".").reverse();
    if (subdomain === 'ios'){
      window.location.replace('https://www.icloud.com/shortcuts/56aeb8b60bcf45b8b3fcb6322517350d')
    }
  },[])
  return (
    <div align="center" style={{ padding: 80 }}>
      <p
        style={{
          color: 'cornflowerblue',
          fontSize: 50,
          fontWeight: 'bold',
        }}
      >
        Gatsby Ant Design Docs Boilerplate
      </p>
      <h2>A gatsby starter to create documentation websites</h2>
      <p>
        This is a fork of{' '}
        <a href="https://github.com/cvluca/gatsby-starter-markdown">
          https://github.com/cvluca/gatsby-starter-markdown
        </a>
      </p>
      <br />

    </div>
  )
}

export default IndexPage
